import React, { useState } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'

const Join = () => {
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isSending, setIsSending] = useState(false)

    // const form = useRef()

    const sendEmail = async (e) => {
        e.preventDefault();

        if(name === '' || number === '' || email === '' || message === '') {
            alert("Preencha todos os campos!")
            return;
        }

        setIsSending(true)

        const templateParams = {
            from_name: name,
            message: message,
            email: email,
            number: number
        }
        
        const response = await emailjs.send('service_tco39sf', 'template_9ymj4xm', templateParams, 'SkcSoThx6W5MqEMVo')
        console.log("Email enviado", response.status, response.text)
        setName('')
        setNumber('')
        setMessage('')
        setEmail('')

        setIsSending(false)
    }

  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span>SUA</span>
                <span className='stroke-text'> MELHOR VERSÃO</span>
            </div>
            <div>
                <span>COMEÇA</span>
                <span>AQUI,</span>
            </div>
            <div>
                <span className='stroke-text'>VAMOS</span>
                <span className='stroke-text'>JUNTOS?</span>
            </div>
        </div>
        <div className="right-j">
            <h1 className='title'>Contato</h1>
            <form className="form-container" onSubmit={sendEmail}>
                <input 
                    className='input' 
                    type="text" 
                    placeholder='Digite seu nome' 
                    onChange={(e) => setName(e.target.value)} 
                    value={name}
                />

                <input 
                    className='input'
                    type="text" 
                    placeholder='Digite seu telefone'
                    onChange={(e) => setNumber(e.target.value)}
                    value={number} 
                />
                <input 
                    className='input'
                    type="email" 
                    placeholder='Digite seu email'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email} 
                />
                <textarea 
                    className='textearea'
                    placeholder="Digite sua mensagem..."
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />

                <input className='button' type="submit" value= {isSending ? "Enviando..." : "Enviar agora"} />
            </form>
        </div>

    </div>
  )
}

export default Join