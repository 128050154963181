export const plansData = [
  {
    icon: (
      <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.11111 18L0 1.5L8.55556 9L14 0L19.4444 9L28 1.5L24.8889 18H3.11111ZM24.8889 22.5C24.8889 23.4 24.2667 24 23.3333 24H4.66667C3.73333 24 3.11111 23.4 3.11111 22.5V21H24.8889V22.5Z" />
      </svg>
    ),
    name: "PLANO MENSAL",
    price: "200,00",
    features: [
      "Preço promocional",
      "Acompanhamento remoto durante um mês",
      "Treinos personalizados enviados de forma digital",
    ],
  },
  {
    icon: (
      <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.11111 18L0 1.5L8.55556 9L14 0L19.4444 9L28 1.5L24.8889 18H3.11111ZM24.8889 22.5C24.8889 23.4 24.2667 24 23.3333 24H4.66667C3.73333 24 3.11111 23.4 3.11111 22.5V21H24.8889V22.5Z" />
      </svg>
    ),
    name: "PLANO BIMESTRAL",
    price: "330,00",
    features: [
      "Desconto de R$ 70,00",
      "Acompanhamento remoto durante 2 meses",
      "Treinos personalizados enviados de forma digital",
    ],
  },
  {
    icon: (
      <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.11111 18L0 1.5L8.55556 9L14 0L19.4444 9L28 1.5L24.8889 18H3.11111ZM24.8889 22.5C24.8889 23.4 24.2667 24 23.3333 24H4.66667C3.73333 24 3.11111 23.4 3.11111 22.5V21H24.8889V22.5Z" />
      </svg>
    ),
    name: "PLANO TRIMESTRAL",
    price: "430,00",
    features: [
      "Desconto de R$ 170,00",
      "Acompanhamento remoto durante 3 meses",
      "Treinos personalizados enviados de forma digital",
    ],
  },
  
];
