import image1 from "../assets/menciaaluna.jpeg";
import image2 from "../assets/raissaaluna.jpeg";
import image3 from "../assets/luanaaluna.jpeg";
import alunofoto from "../assets/aluno1.jpeg"

export const testimonialsData = [
  {
    image: image1,
    review:
      "Sua consultoria é perfeita, além do normal. Você é atenciosa. Te indico para todo mundo!",
    name: 'Mencia',
    status : '- Aluna de Consultoria Online'
  },
  {
    image: image2,
    review: "Os treinos são simplesmente incríveis, pois você estabelece tudo aquilo que vê necessidade para cada pessoa. O planejamento do tempo de treino, de acordo com as particularidades do aluno é essencial para quem está na correria do dia a dia e quer manter uma vida saudável ativa. Então, só tenho a dizer que te indico para todos aqueles que queiram mudar de vida e sair da zona de conforto. ",
    name: 'Raissa Novaes',
    status : '- Aluna de Consultoria Online'
  },
  {
    image : image3,
    review:'Posso lhe dizer com propriedade que nunca trabalhei tanto o emocional como trabalhamos nesses 2 meses de acompanhamento on-line. Controlar os desejos e conseguir, me deu prazeres maiores do que simplesmente ceder e sabotar meu objetivo! Muito, muito obrigada, não pelo resultado de hoje, mas pelo aprendizado diário, pela força e entrega total que você faz na consultoria. Parabéns, Jaylma, pelo lindo trabalho, muito mais que treinar pessoas, você treina nossa mente e espírito.',
    name: 'Luana',
    status : '- Aluna de Consultoria Online'
  },
  {
    image : alunofoto,
    review:'Tava aqui me olhando no espelho e tô gostando desses treinos, viu? Estou vendo um resultado que não imaginava ver com tão pouco tempo de treino. Como tinha lhe falado, eu tinha receio no meu peito e hoje, já estou notando diferença nele e estou começando a gostar.',
    name: 'Ricardo Gondim',
    status : ' - Aluno de Consultoria Online'
  }
];
