import React, { useState } from 'react'
import './Header.css'
import Logo from '../../assets/logositee.png';
import Bars from '../../assets/bars.png';
import { Link } from 'react-scroll';

const Header = () => {
  const mobile = window.innerWidth<=768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <div className="header">
        <img src={Logo} alt="" className='logo'/> 
        {(menuOpened === false && mobile === true) ? (
          <div
            style={{
              backgroundColor: "var(--appColor)",
              opacity: 0.4,
              marginTop: "1rem",
              padding: "0.1rem",
              borderRadius: "5px",
            }}
            
            onClick={() => setMenuOpened(true)}
          >
            <img src={Bars} alt="" style={{width: '1.5rem', height: '1.5rem', padding: '1rem'}} />
          </div>
        ): (
        <ul className='header-menu'>
          <li><Link
            onClick={() => setMenuOpened(false)}
            activeClass='active'
            to='home'
            spy={true}
            smooth={true}
          >Início</Link>
            </li>
          <li><Link
          onClick={() => setMenuOpened(false)}
            to='services'
            spy={true}
            smooth={true}
          >Serviços
          </Link></li>
          <li><Link
          onClick={() => setMenuOpened(false)}
            to='about'
            spy={true}
            smooth={true}
          >Sobre mim</Link> </li>
          <li ><Link
          onClick={() => setMenuOpened(false)}
            to='plans'
            spy={true}
            smooth={true}
          >Planos
          </Link></li>
          <li><Link
          onClick={() => setMenuOpened(false)}
            to='testimonials'
            spy={true}
            smooth={true}
            >Depoimentos</Link>
          </li>
        </ul> 
      )}
        
    </div>
  )
}

export default Header