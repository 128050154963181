import React from 'react'
import { plansData } from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
import './Plans.css'

const Plans = () => {
  return (
    <div className="plans-container">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
        <div className="programs-header" style={{gap: '2rem'}}>
            <span className='stroke-text'>Comece</span>
            <span> sua jornada</span>
            <span className='stroke-text'> agora</span>
        </div>

        {/* planos */}
        <div className="plans">
            {plansData.map((plan, i) => (
                <div className="plan" key={i}>
                    {plan.icon}
                    <span>{plan.name}</span>
                    <span> R$ {plan.price}</span>

                    <div className="features">
                        {plan.features.map((feature, i) => (
                            <div className="feature">
                                <img src={whiteTick} alt="" />
                                <span key={i}>{feature}</span>
                            </div>
                        ))}
                    </div>
                    <div>
                        <span>* Formas de pagamento: Pix ou cartão dividido em até 3 vezes.</span>
                    </div>
                        <button className="btn-fatures">
                            <a href='https://wa.me/5587992048194?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20como%20funciona%20sua%20consultoria.%20%0AVoc%C3%AA%20pode%20me%20ajudar%3F' target='_blank' rel="noreferrer">Saber mais</a>
                        </button>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Plans