import React from 'react'
import './About.css'
import Imagem1 from '../../assets/IMG_9500.png'
import Imagem2 from '../../assets/IMG_9501.png'
import Imagem3 from '../../assets/IMG_9507.png'
import Imagem4 from '../../assets/IMG_9511.png'
import Imagem5 from '../../assets/IMG_9516.png'


const About = () => {
  return (
    <div className="About" id='about'>
        <div className="left-r">
            <img src={Imagem1} alt="" />
            <img src={Imagem2} alt="" />
            <img src={Imagem3} alt="" />
            <img src={Imagem4} alt="" />
            <img src={Imagem5} alt="" />

        </div>

        <div className="right-r">
            {/* <span>Conheça</span> */}
            <div>
                <span className='stroke-text'>minha</span>
                <span> história</span>
            </div>

            <div className='details-r'>

                <p>
                        Sou natural de Irerê, Bahia, e cresci em Pernambuco, onde estudei em escolas públicas. 
                        Aos 15 anos, interrompi os estudos por quatro anos, mas com determinação, retomei e concluí o ensino médio. 
                        Durante esse período, descobri minha paixão por saúde e bem-estar através da musculação. <br></br><br></br>

                        Iniciei meus estudos em Administração, mas percebi que meu verdadeiro propósito estava na Educação Física, área em que me graduei e encontrei minha missão: ajudar pessoas a melhorar sua saúde e qualidade de vida através do exercício físico. 
                        Desde então, venho me aprimorando constantemente para oferecer o melhor aos meus alunos, promovendo bem-estar com dedicação e comprometimento.
                </p>
            </div>
        </div>
    </div>
  )
}

export default About