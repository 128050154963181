import React from 'react'
import './Hero.css'
// import Heart from '../../assets/heart.png'
import Header from '../Header/Header'
import hero_image from '../../assets/hero-imagem.png'
import hero_image_back from '../../assets/hero_image_back.png'
// import Calories from '../../assets/calories.png'
import { motion } from 'framer-motion'

const Hero = () => {

    const transition = {type: 'spring', duration: 3}
    // const mobile = window.innerWidth<=768 ? true: false;

  return (
    <div className="hero" id='home'>
        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header />
            {/* frase de impacto */}
            {/* <div className="the-best-ad">
                <motion.div
                    initial={{left: mobile ? "178px": '238px' }}
                    whileInView={{left: '8px'}}
                    transition={{...transition, type: 'tween'}}
                ></motion.div>
                <span>TREINE COM INTENSIDADE E DIVIRTA-SE COM ISSO</span>
            </div> */}

            {/*  */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>conheça </span>
                    <span>a minha </span>
                </div>
                <div>
                    <span>consultoria</span>
                </div>
                <div>
                    <span>
                        Chegou a hora de conquistar o corpo que você sempre sonhou
                    </span>
                </div>
            </div>

            {/* figuras*/}
            <div className="figures">
                <div>
                    <span>emagrecimento</span>
                </div>
                <div>
                    <span>hipertrofia</span>
                </div>
                <div>
                    <span>definição muscular</span>
                </div>
            </div>
            {/* botoes */}
            {/* <div className="hero-buttons">
                <buttons className="btn">
                    <a href='https://wa.me/5587992048194?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20como%20funciona%20sua%20consultoria.%20%0AVoc%C3%AA%20pode%20me%20ajudar%3F' target='_blank' rel="noreferrer" >Começar agora</a>
                </buttons>
                <buttons className="btn">
                    <a href='https://wa.me/5587992048194?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20como%20funciona%20sua%20consultoria.%20%0AVoc%C3%AA%20pode%20me%20ajudar%3F' target='_blank' rel="noreferrer">Saber mais</a>
                </buttons>
            </div> */}
        </div>
        <div className="right-h"> 
            {/* <button className='btn'>
                <a href='https://wa.me/5587992048194?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20como%20funciona%20sua%20consultoria.%20%0AVoc%C3%AA%20pode%20me%20ajudar%3F' target='_blank' rel='noreferrer'> Juntar-se agora</a>
            </button> */}

            {/* <motion.div
                initial={{right: "-1rem"}}
                whileInView={{ right: '4rem'}}
                transition={transition}  
                className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Frequễncia Cardíaca</span>
                    <span> 116 bpm</span>
            </motion.div> */}

            {/* hero images */}
            <img src={hero_image} alt="" className='hero-image' />
            <motion.img
            initial={{right: "11rem"}}
            whileInView={{ right: '19rem'}}
            transition={transition}  
            src={hero_image_back} alt="" className='hero-image-back' />

            {/* calories */}
            {/* <motion.div
                initial={{right: "37rem"}}
                whileInView={{ right: '28rem'}}
                transition={transition}   
                className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calorias queimadas</span><span>220 kcal</span>
                </div>
            </motion.div> */}
        </div>

    </div>
  )
}

export default Hero