import React from 'react'
import './Services.css'
import { servicesData } from '../../data/servicesData'
// import RightArrow from '../../assets/rightArrow.png'


const Services = () => {
  return (
    <div className="Services" id="services">
      {/* header */}
      <div className="services-header">
        <span className='stroke-text'>Explore</span>
        <span> meus</span>
        <span className='stroke-text'> serviços</span>
      </div>

      <div className="services-categories">
        {servicesData.map((service) => (
          <div className="category">
            {service.image}
            <span>{service.heading}</span>
            <span>{service.details}</span>
            {/* <div className="join-now">
              <span>Juntar-se agora</span><img src={RightArrow} alt="" />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Services